import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

function Community() {
  return (
    <Container className="mt-3 mb-3">
      <Row>
        <Col md lg="8" className="mx-auto">
          <Card>
            <Card.Body>
              <Card.Title>Cùng tham gia cộng đồng mật thư tại:</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    <a href="https://zalo.me/g/dqkxmt743" target="_blank" rel="noreferrer">Group Zalo</a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/groups/matthuonline/" target="_blank" rel="noreferrer">Group Facebook</a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/matthu.vn" target="_blank" rel="noreferrer">Fanpage Facebook</a>
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Community;
