import React from "react";
import Card from "react-bootstrap/Card";
import { Col } from "react-bootstrap";

function RightSide() {
  return (
    <Col md lg="4">
      <Card>
        <Card.Body>
          <Card.Title>Bài viết</Card.Title>
          <ul className="list-unstyled">
            <li>
              <a href="/mat-thu-la-gi#">Mật thư là gì? Khái niệm cơ bản về mật thư</a>
            </li>
            <li>
              <a href="/morse-la-gi#">Morse là gì?</a>
            </li>
            <li>
              <a href="/semaphore-la-gi">Semaphore là gì?</a>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default RightSide;
