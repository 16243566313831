import React from "react";
import { Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';

function SubCipher() {

    var saved_key = localStorage.getItem("key");
    if (saved_key) {
        $("#txtKey").val(saved_key);
    }

    var str_alphabet = "abcdefghijklmnopqrstuvwxyz0123456789";
    var arr_alphabet = str_alphabet.split("");

    var str_key = "";
    var arr_key = [];

    $('#txtPlain').bind('input', function () {
        let value = this.value;
        if (value.length) {
            var match = value.match(/[^a-zA-Z0-9 ]+/g);
            if (match) {
                match.forEach(function (text) {
                    $('#txtPlain').val(value.replace(text, ""))
                })
            }
        }
    });

    function getKey() {
        str_key = $("#txtKey").val().trim();
        arr_key = str_key.split(" ");
    }

    function isSingleKey() {
        let len = arr_key[0].length;
        for (let i = 0; i < arr_key.length; i++) {
            if (len !== arr_key[i].length) {
                return false
            }
        }
        return true
    }

    function isKeyInAlphabet() {
        for (let i = 0; i < arr_key.length; i++) {
            if (str_alphabet.indexOf(arr_key[i]) >= 0) {
                return true
            }
        }
        return false
    }

    $("#btEncrypt").click(function () {
        getKey();
        localStorage.setItem("key", str_key);

        let text = $("#txtPlain").val().toLowerCase().trim();
        let result = "";
        for (let i = 0; i < text.length; i++) {
            let index = str_alphabet.indexOf(text[i]);
            if (index >= 0 && index < arr_key.length) {
                result += arr_key[index];
            } else {
                result += text[i];
            }
        }
        $("#txtCipher").val(result);
    });

    $("#btReverse").click(function () {
        getKey();
        let text = $("#txtCipher").val().trim();

        if (str_key.length > 36 && !isSingleKey()) {
            let arr_cipher = text.split(" ");
            let result = "";

            for (let i = 0; i < arr_cipher.length; i++) {
                let index = arr_key.indexOf(arr_cipher[i]);
                if (index >= 0 && index < arr_key.length) {
                    result += str_alphabet[index];
                    result += " ";
                } else {
                    result += arr_cipher[i];
                }
            }
            $("#txtPlain").val(result);
        } else if (isKeyInAlphabet()) {
            let result = "";
            for (let i = 0; i < text.length; i++) {
                let index = arr_key.indexOf(text[i]);
                if (index >= 0 && index < arr_key.length) {
                    result += arr_alphabet[index];
                } else {
                    result += text[i];
                }
            }
            $("#txtPlain").val(result);
        } else {
            for (let i = 0; i < arr_key.length; i++) {
                if (text.indexOf(arr_key[i]) >= 0) {
                    text = text.replaceAll(arr_key[i], arr_alphabet[i]);
                }
            }
            $("#txtPlain").val(text);
        }
    });

    $(".btn-copy").click(function () {
        let _for = $(this).attr("for");
        if (_for) {
            let copyText = document.getElementById($(this).attr("for"));
            if (copyText.value.length) {
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");
            }
        }
    });

    return (
        <Container>
            <script src="/sub-cipher.js"></script>
            <Row>
                <div className="col-md-8 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title m-2 text-center">Mật thư thay thế</h1>
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="txtKey">🔑 Khóa</Form.Label>
                                    <button type="button" className="btn btn-sm btn-copy" htmlFor="txtKey" title="Copy Key">
                                        <i class="bi bi-copy"></i>
                                    </button>
                                    <Form.Control
                                        rows="3"
                                        id="txtKey"
                                        as="textarea"
                                        defaultValue="😀 😃 😁 😅 🥰 🤣 🥲 ☺️ 😊 😋 😇 😉 😒 😞 😙 😟 😕 🙂 🙃 ☹️ 😡 😍 😳 😩 😭 😠 🤬 🤯 😳 🥵 🥶 😱 😨 😰 😥 😓"
                                    />
                                    <small className="text-muted">Tương ứng với chuỗi "a b c d e f g h i j k l m
                                        n o p q r s t u v w x y z 0 1 2 3 4 5 6 7 8 9" (Cách nhau bởi khoảng trắng)</small>
                                </Form.Group>
                                <Form.Group className="form-group mt-3">
                                    <Form.Label htmlFor="txtPlain">📝 Văn bản gốc</Form.Label>
                                    <button type="Button" className="btn btn-sm btn-copy" htmlFor="txtPlain" title='Copy văn bản gốc'>
                                        <i class="bi bi-copy"></i>
                                    </button>
                                    <Form.Control
                                        rows="5"
                                        id="txtPlain"
                                        as="textarea"
                                        placeholder="Chỉ hỗ trợ a-z 0-9"
                                        pattern="[a-zA-Z0-9.+,$-]+"
                                    />
                                </Form.Group>
                                <div className="text-center m-4">
                                    <Button type="button" variant="primary" id="btEncrypt" title="Encrypt">▼</Button>&ensp;
                                    <Button type="button" variant="primary" id="btReverse" title="Reverse">▲</Button>
                                </div>
                                <Form.Group>
                                    <Form.Label htmlFor="txtCipher">🔒 Mật thư</Form.Label>
                                    <button type="button" className="btn btn-sm btn-copy my-2" htmlFor="txtCipher" title='Copy mật thư'>
                                        <i class="bi bi-copy"></i>
                                    </button>
                                    <Form.Control
                                        rows="5"
                                        id="txtCipher"
                                        as="textarea"
                                    />
                                </Form.Group>
                                <h4 className="mt-4">Một số khóa</h4>
                                <Form.Control rows="2" as="textarea" className="mb-2" readOnly defaultValue="😀 😃 😁 😅 🥰 🤣 🥲 ☺️ 😊 😋 😇 😉 😒 😞 😙 😟 😕 🙂 🙃 ☹️ 😡 😍 😳 😩 😭 😠 🤬 🤯 😳 🥵 🥶 😱 😨 😰 😥 😓" />
                                <Form.Control rows="2" as="textarea" className="mb-2" readOnly defaultValue="z y x w v u t s r q p o n m l k j i h g f e d c b a 9 8 7 6 5 4 3 2 1 0" />
                                <Form.Control rows="2" as="textarea" className="mb-2" readOnly defaultValue=".- -... -.-. -.. . ..-. --. .... .. .--- -.- .-.. -- -. --- .--. --.- .-. ... - ..- ...- .-- -..- -.-- --.. ----- .---- ..--- ...-- ....- ..... -.... --... ---.. ----." />
                                <Form.Control rows="2" as="textarea" className="mb-2" readOnly defaultValue="6:35 6:45 6:50 6:00 6:05 6:15 6:25 7:45 7:50 12:15 12:50 7:05 7:15 7:25 9:50 9:00 9:05 9:15 9:25 10:00 10:05 10:25 1:15 1:25 10:15 3:25" />
                                <Form.Control rows="2" as="textarea" className="mb-2" readOnly defaultValue="Trèo lên cây bưởi hái hoa, Bước xuống vườn cà hái nụ tầm xuân. Nụ tầm xuân nở ra xanh biếc, Em đã có chồng, anh tiếc lắm thay! Ba đồng một mớ trầu cay, Sao anh không hỏi những ngày còn không?" />
                            </Form>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    );
}

export default SubCipher;
