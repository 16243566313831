import React from "react";
import RightSide from "../RightSide";

function Morse(props) {
  return (
    <main className="container mt-3 mb-3">
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-3 p-3">
            <div className="row g-0">
              <h1>
                Morse là gì?
              </h1>
              <div className="p2">
                <p>
                  Morse là một hệ thống truyền thông sử dụng các ký hiệu âm thanh hoặc ánh sáng để truyền tải thông điệp. Phương tiện chính của Morse là mã Morse, một hệ thống ký hiệu gồm các dấu và khoảng cách giữa chúng, được phát triển vào những năm 1830 và 1840 bởi Samuel Morse và Alfred Vail.
                </p>
                <p>
                  Mã Morse ban đầu được thiết kế để sử dụng trong việc truyền tải thông điệp qua đường dây điện. Trong hệ thống này, mỗi chữ cái, số và một số dấu câu được biểu diễn bằng các dấu và khoảng trên một đoạn dây điện. Dấu "dot" ngắn thường biểu thị cho chữ cái, số hoặc dấu câu nhất định, trong khi dấu "dash" dài hơn được sử dụng để biểu thị các giá trị khác.
                </p>
                <p>
                  Với sự phổ biến của viễn thông không dây và ngôn ngữ Morse đã trở thành một phương tiện truyền thông quan trọng trong nhiều ngữ cảnh khác nhau. Nó được sử dụng rộng rãi trong ngành hàng hải, giao thông, cấp cứu và nhiều lĩnh vực khác.
                </p>
                <p>
                  Mã Morse có thể được truyền tải thông qua âm thanh (bằng cách sử dụng đèn hoặc loa), ánh sáng (bằng cách sử dụng đèn hoặc tín hiệu ánh sáng), và thậm chí cả việc sử dụng các tín hiệu cơ học như các cờ hoặc tay để tạo ra các dấu và khoảng. Trong khi Morse đã không còn được sử dụng rộng rãi trong truyền thông hàng ngày, nó vẫn là một kỷ nguyên quan trọng trong lịch sử truyền thông và đã đóng góp vào sự phát triển của các phương tiện truyền thông hiện đại.
                </p>
              </div>
            </div>
          </div>
        </div>
        <RightSide />
      </div>
    </main>
  );
}
export default Morse;
