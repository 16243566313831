import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import RightSide from "../RightSide";

function MatThuCanBan(props) {
  return (
    <Container>
      <Row>
        <Col md="8">
          <Card className="p-3">
            <Row>
              <h1>
                Mật thư là gì? Khái niệm cơ bản về mật thư
              </h1>
              <Container>
                <h2>I. Khái Niệm Cơ Bản về Mật Thư và Mật Mã:</h2>
                <ul>
                  <li>
                    <strong>Mật thư</strong>: là từ Việt, dịch rất sát từ Cryptogram có gốc tiếng Hy Lạp: Kryptos là giấu kín và bí mật; và gramma: bản văn, lá thư. Mật thư có nghĩa là bản thông tin được viết bằng các ký hiệu bí mật bằng các ký hiệu thông thường nhưng theo cách sắp xếp bí mật mà người gửi và người nhận đã thỏa thuận trước với nhau nhằm giữ kín nội dung trao đổi.
                  </li>
                  <li>
                    <strong>Mật mã: (ciphen,code)</strong> Là các ký hiệu và cách sắp xếp để thể hiện nội dung bản tin. Mật mã gồm 2 yếu tố: hệ thống và chìa khóa.
                  </li>
                  <li>
                    <strong>Giải mã: (Decinphermant)</strong> Là quá trình khám phá những bí mật của ký hiệu và cách sắp xếp để đọc được nội dung bản tin .
                  </li>
                  <li>
                    <strong>Chìa khóa</strong>: Chìa khóa được đặt ra nhằm mục đích là để nâng cao tính bí mật của bản tin. Chìa khóa là phần gợi ý của người viết mật thư nhằm giúp người giải mật thư đoán biết hệ thống và có cơ sở tìm ra qui luật nhất định đẩ giải mã. Nếu là mật thư đơn giản thì không cần thiết phải có chìa khóa.
                  </li>
                </ul>
                Hệ thống mật thư được qui về 3 dạng cơ bản sau:
                <ul>
                  <li>
                    Hệ thống thay thế.
                  </li>
                  <li>
                    Hệ thống dời chỗ.
                  </li>
                  <li>
                    Hệ thống ẩn dấu.
                  </li>
                </ul>


                <h2>II. Yêu Cầu khi Viết và Đọc Mật Thư:</h2>

                <p>
                  <p>
                    <strong>Viết mật thư</strong>: Muốn mật thư đạt yêu cầu phải có những yếu tố sau: Phải phù hợp với trình độ, trí tuệ và kinh nghiệm của người giải mật thư. Có nghĩa là phải biết người nhận mật thư trình độ tư duy ra sao? Biết dùng chìa khóa và hệ thống nào? Mật thư phải có ít nhiều tính cách bí ẩn bắt người chơi phải động não. Mật thư đã chơi ở buổi trại lần trước rồi, muốn sử dụng lại thì nên thay đổi vài chi tiết cơ bản. Viết mật thư phải nghĩ đến chìa khóa, đặt chìa khóa phải nghĩ đến người nhận mật thư, đừng theo chủ quan của mình. Nếu mật thư quá khó sẽ gây sự đánh đố dẫn đến trò chơi mất hay, tốn nhiều thời gian. Viết mật thư phải cẩn thận, cân nhắc sao cho phù hợp với hoàn cảnh, mục đích, yêu cầu và thời gian của toàn buổi trại hay buổi sinh hoạt. Viết xong mật thư, cần kiểm tra lại xem có sai xót ở chỗ nào không? Nội dung đã đủ và đúng chưa ? chìa khóa có gì sai lệch và có logic chưa?
                  </p>
                  <p>
                    <strong>Đọc mật thư</strong>: Trước hết phải bình tĩnh và thận trọng tìm ra ý nghĩa của chìa khóa. Chìa khóa bao giờ cũng liên quan chặt chẽ đến mật thư. Giải ý nghĩa của chìa khóa phải có cơ sở, hợp logic với mật thư. Chìa khóa có thể tìm ra được rất nhiều ý nghĩa khác nhau, nhưng quan trọng là ý nghĩa nào khớp với mật thư. Từ chìa khóa, ta có thể xác định mật thư thuộc hệ thống nào. Sau đó bắt đầu dịch mật thư. Nếu dịch ra thấy sai một vài chỗ sai có thể do:
                  </p>
                  <p>
                    Chưa tìm đúng ý nghĩa của chìa khóa (phải thử lại cách khác), “Dịch” chưa đúng nghĩa chìa khóa (Phải kiểm tra lại), Người gửi viết sai ký hiệu (có thể do cố ý viết sai).
                  </p>
                  <p>
                    Dịch mật thư xong, rồi chép lại toàn bộ nội dung đã “dịch”, thấy chỗ nào không hợp lý, khác lạ thì phải cẩn thận chú ý, cân nhắc thật kỹ, chớ đoán mò hoặc vội kết luận.
                  </p>
                </p>
                <h2>III. Hướng Dẫn Giải Mật Thư:</h2>
                Mật thư thường có 2 phần:
                <br />
                1. <strong>Bản mật mã</strong>: Là những ký tự hoặc hình vẽ, thoạt đầu có vẽ rất khó hiểu. Sau khi nghiên cứu kỹ chìa khóa, ta sẽ tìm ra hướng giải bằng cách đối chiếu những dữ kiện mà chìa khoá đã gợi ý.
                <br />
                2. <strong>Chìa khóa</strong> (Ký hiệu OTT): Là một hình thức gợi ý cho người dịch tìm ra hướng giải mật thư. Chìa khóa có thể là một câu thơ hoặc một ký hiệu nào đó bằng hình vẽ.
                <br />
                Sau khi giải mã xong, ta sẽ được một bản văn hoàn chỉnh, ta gọi đó là "Bạch văn": Là một văn bản hoàn chỉnh, tức là sau khi dịch xong, ta viết ra thành một bức thư bình thường mà ai cũng có thể đọc được.
                <br />
                Một số từ chuyên môn:
                <br />
                <ul>
                  <li>
                    Văn bản gốc (bạch văn): nội dung cần truyền đạt (bản tin).
                  </li>
                  <li>
                    Khoá: dung để hướng dẫn cách giải. Ký hiệu:
                  </li>
                  <li>
                    Mã khoá: chuyển bạch văn sang dạng mật thư.
                  </li>
                  <li>
                    Dịch mã: chuyển thư sang dạng bạch văn (quá trình dịch mã).
                  </li>
                </ul>
                Tuỳ theo quan điểm sắp xếp và cách sử dụng chúng ta có nhiều cách sắp xếp theo các hệ thống mật thư khác nhau.
                <h2>IV. Các Bước Soạn Một Mật Thư:</h2>
                <p>
                  Khi cần soạn ra một mật thư ta cần làm theo các bước sau:
                  <br />
                  <ol>
                    <li>
                      <strong>Bước 1: Viết nội dung bức thư (bạch văn).</strong>
                    </li>
                    Ta nghĩ ra một nội dung nào đó cần truyền đạt đến người khác. Viết ra đầy đủ (chú ý các dấu thanh và dấu mũ) ngắn gọn và đầy đủ ý, không dài dòng.
                    <li>
                      <strong>Bước 2: Chọn dạng mật thư.</strong>
                    </li>
                    Chọn dạng mật thư nào đó sao cho phù hợp với trình độ của người nhận mật thư.
                    <li>
                      <strong>Bước 3: Mã hoá.</strong>
                    </li>
                    Căn cứ theo yêu cầu của Mật thư, ta cần lượt chuyển những từ ngữ của nội dung bản tin thành mât mã. Đối chiếu thật cẩn thận để tránh bị sai sót.
                    <li>
                      <strong>Bước 4: Cho chìa khoá (OTT)</strong>
                    </li>
                    Chìa khoá phải sáng sủa, rõ ràng, gợi ý cho người dịch dễ dàng tìm được hướng giải. đừng để người giải phải mất thì giờ giải cái chìa khoá của ta đưa ra.
                  </ol>
                </p>
              </Container>
            </Row>
          </Card>
        </Col>
        <RightSide />
      </Row>
    </Container>
  );
}
export default MatThuCanBan;
