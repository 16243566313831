import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <header className="bg-white mb-3">
      <Navbar
        expanded={expand}
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="/#" className="d-flex">
            MẬT THƯ
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
          >
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" defaultActiveKey="#home">
              <NavDropdown title="Công cụ" id="basic-nav-dropdown">
                <NavDropdown.Item href="/cong-cu/mat-thu-thay-the">Mật thư thay thế</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Ứng dụng" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://play.google.com/store/apps/details?id=dieuk.matthuonline" target="_blank">Mật Thư Online</NavDropdown.Item>
                <NavDropdown.Item href="https://play.google.com/store/apps/details?id=dieuk.semoplay" target="_blank">SEMO Play</NavDropdown.Item>
                <NavDropdown.Item href="https://play.google.com/store/apps/details?id=dieuk.gmmt" target="_blank">Giải Mã Mật Thư</NavDropdown.Item>
                <NavDropdown.Item href="https://play.google.com/store/apps/details?id=com.semaphore.translate" target="_blank">Semaphore Translate</NavDropdown.Item>
              </NavDropdown>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/cong-dong"
                  onClick={() => updateExpanded(false)}>
                  Cộng đồng
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default NavBar;
