import React from "react";
import RightSide from "../RightSide";

function Semaphore(props) {
  return (
    <main className="container mt-3 mb-3">
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-3 p-3">
            <div className="row g-0">
              <h1>
                Semaphore là gì?
              </h1>
              <div className="p2">
                <p>
                  Semaphore là một hệ thống truyền thông sử dụng các tín hiệu cơ học để truyền tải thông điệp. Phương tiện chính của semaphore là các cờ hoặc bảng cờ được sử dụng để biểu thị thông điệp thông qua các vị trí hoặc góc đặc biệt.
                </p>
                <p>
                  Hệ thống semaphore thường được sử dụng để truyền tải thông điệp giữa các điểm xa nhau, đặc biệt là trong môi trường quân sự hoặc hàng hải. Các tín hiệu semaphore thường được thực hiện bằng cách sử dụng cờ có các màu sắc khác nhau hoặc các biểu tượng đặc biệt để truyền đạt các ý nghĩa khác nhau.
                </p>
                <p>
                  Một ví dụ phổ biến về semaphore là hệ thống cờ ngũ quả cầu, nơi mỗi cờ có một màu sắc hoặc biểu tượng đặc biệt và có thể được giữ ở một vị trí cụ thể. Bằng cách di chuyển các cờ vào các vị trí khác nhau, người gửi và người nhận có thể truyền tải các thông điệp khác nhau.
                </p>
                <p>
                  Tính linh hoạt và tương tác trực tiếp của hệ thống semaphore làm cho nó trở thành một công cụ hiệu quả trong môi trường nơi việc sử dụng các phương tiện truyền thông điện tử có thể bị hạn chế hoặc không khả dụng. Mặc dù semaphore không còn được sử dụng phổ biến như trước đây, nhưng nó vẫn là một phần của lịch sử truyền thông và được giữ lại trong một số hoạt động và sự kiện truyền thống.
                </p>
              </div>
            </div>
          </div>
        </div>
        <RightSide />
      </div>
    </main>
  );
}
export default Semaphore;
