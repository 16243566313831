import React from "react";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer id="footer" className="py-4 text-muted text-center bg-white mt-3">
      <p>Copyright © {year} Matthu.vn</p>
    </footer>
  );
}

export default Footer;
