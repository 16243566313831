import React from "react";
import { Container, Row } from "react-bootstrap";

import RightSide from "./RightSide";

function Home() {
  return (
    <Container>
      <Row>
        <div className="col-md-8">
          <div className="card mb-3">
            <div className="row g-0">
              <div className="col-md-4">
                <img src="https://play-lh.googleusercontent.com/mandMWEzCSr2w2ZRYlTHifg4L7Nj3xBu0Okw44juXJJ1mzD_RewiZfdcxiYRH2VzN7CR" className="img-thumbnail rounded mx-auto d-block" style={{height: '80%'}} alt="Mật thư là gì" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <a href="/mat-thu-la-gi">
                    <h5 className="card-title">Mật thư là gì? Khái niệm cơ bản về mật thư</h5>
                  </a>
                  <p className="card-text">Mật thư - Cryptogram có gốc tiếng Hy Lạp: Kryptos là giấu kín và bí mật; và gramma: bản văn, lá thư. Mật thư có nghĩa là bản thông tin được viết bằng các ký hiệu bí mật bằng các ký hiệu thông thường nhưng theo cách sắp xếp bí mật mà người gửi và người nhận đã thỏa thuận trước với nhau nhằm giữ kín nội dung trao đổi.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="row g-0">
              <div className="col-md-4">
                <img src="https://fususu.com/wp-content/uploads/2019/08/bang-morse.gif" className="img-thumbnail rounded mx-auto d-block" style={{height: '80%'}} alt="Mật thư là gì" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <a href="/morse-la-gi">
                    <h5 className="card-title">Mã Morse là gì?</h5>
                  </a>
                  <p className="card-text">Mã Morse là một hệ thống mã hóa ký tự, sử dụng các chuỗi xung điện "ngắn" hoặc "dài", hay "chấm" (.) hoặc "gạch" (-) để đại diện cho các chữ cái, số và ký hiệu. Nó được đặt theo tên của Samuel Morse, nhà phát minh của điện báo.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="row g-0">
              <div className="col-md-4">
                <img src="https://eludegames.com.au/wp-content/uploads/Semaphore-Blog.png" className="img-thumbnail rounded mx-auto d-block" style={{height: '80%'}} alt="Mật thư là gì" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <a href="/semaphore-la-gi">
                    <h5 className="card-title">Semaphore là gì?</h5>
                  </a>
                  <p className="card-text">Semaphore là một hệ thống truyền thông sử dụng các tín hiệu cơ học để truyền tải thông điệp. Phương tiện chính của semaphore là các cờ hoặc bảng cờ được sử dụng để biểu thị thông điệp thông qua các vị trí hoặc góc đặc biệt.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSide />
      </Row>
    </Container>
  );
}

export default Home;
