import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Community from "./components/Community";
import Footer from "./components/Footer";
import SubCipher from "./components/Tools/SubCipher";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import MatThuCanBan from "./components/Posts/MatThuCanBan";
import Semaphore from "./components/Posts/Semaphore";
import Morse from "./components/Posts/Morse";

function App() {
  const [, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cong-cu/mat-thu-thay-the" element={<SubCipher />} />
        <Route path="/cong-dong" element={<Community />} />
        <Route path="/mat-thu-la-gi" element={<MatThuCanBan />} />
        <Route path="/semaphore-la-gi" element={<Semaphore />} />
        <Route path="/morse-la-gi" element={<Morse />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
